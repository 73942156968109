<!--
 * @Author: 矢车
 * @Date: 2020-07-08 14:10:33
 * @LastEditors: 矢车
 * @LastEditTime: 2020-09-17 11:39:13
 * @Descripttion:
-->
<template>
  <div>
    <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" @submit="handleSubmit">
      <a-form-item label="品牌">
        <a-select allowClear :default-active-first-option="false" :show-arrow="false" :filter-option="false" :not-found-content="null" show-search @search="brandSerach" @change="brandHandleChange" v-decorator="['title', { rules: [{ required: true, message: '请输入品牌名称' }], initialValue: shopInfo.title}]">
          <a-select-option v-for="item in formData.brandList" :key="item.id" :value="item.id">{{item.name}}</a-select-option>
        </a-select>
        <p class="tipBrand">{{isBrandExit}}</p>
      </a-form-item>
      <a-form-item label="商品渠道">
        <a-select v-decorator="['goods_channel', { rules: [{ required: true, message: '请选择商品渠道' }], initialValue: formData.goods_channel == '' ?  '' : formData.goods_channel==1 ? '淘宝联盟' : '抖音小店' }]">
          <a-select-option value="">请选择</a-select-option>
<!--          <a-select-option value="1">淘宝联盟</a-select-option>-->
          <a-select-option value="2">抖音小店</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="专场类目">
        <a-select v-decorator="['custom_cat', { rules: [{ required: true, message: '请选择专场类目' }], initialValue: formData.custom_cat == '' ?  '' : custom_catFormat }]">
          <a-select-option value="">请选择</a-select-option>
          <a-select-option value="8">女装</a-select-option>
          <a-select-option value="1">美妆个护</a-select-option>
          <a-select-option value="4">美食</a-select-option>
          <a-select-option value="3">居家百货</a-select-option>
          <a-select-option value="10">母婴</a-select-option>
          <a-select-option value="9">男装</a-select-option>
          <a-select-option value="5">鞋品箱包</a-select-option>
          <a-select-option value="7">珠宝配饰</a-select-option>
          <a-select-option value="12">数码家电</a-select-option>
          <a-select-option value="11">户外运动</a-select-option>
          <a-select-option value="6">文娱车品</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="最低等级">
        <a-select v-decorator="['kuran_level_require', { rules: [{ required: true, message: '请选择最低等级' }], initialValue: formData.kuran_level_require == '' ?  '' : kuranFormat }]">
          <a-select-option value="">请选择</a-select-option>
          <a-select-option value="1">LV1</a-select-option>
          <a-select-option value="2">LV2</a-select-option>
          <a-select-option value="3">LV3</a-select-option>
          <a-select-option value="4">LV4</a-select-option>
          <a-select-option value="5">LV5</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="排序权重">
        <a-input v-model="formData.weight" type="number"  min="0" />
      </a-form-item>
      <a-form-item label="标签1">
        <a-input :maxLength=6 v-model="formData.tag1"/>
      </a-form-item>
      <a-form-item label="标签2">
        <a-input :maxLength=6 v-model="formData.tag2" />
      </a-form-item>
      <a-form-item label="专场说明">
        <a-input v-model="formData.instruction" type="textarea" :auto-size="{ minRows: 4, maxRows: 6 }" :maxLength=100 />
      </a-form-item>
      <a-form-item class="shopInfo" label="商品信息">
        <div class="brand-info">
          <a-input allowClear v-model.trim="formData.addShopId" />
          <a-button type="primary" @click="checkGoodsData">添加</a-button>
        </div>
      </a-form-item>
      <div class="container">
        <div class="item" v-for="(items,i) in shopList" :key="items.item_id_id" v-dragging="{ list: shopList, item: items, group: 'knowTab' }">
          <div class="pic"><img :src="items.item_id__pict_url" alt=""></div>
          <div class="con-item">
            <div class="descript omit-three">
              <img class="channelImg" :src="items.channel_img" alt="">
              {{items.item_id__title}}
            </div>
            <div class="price">
              <span class="p-money">￥{{items.show_price }}</span>
              <span class="p-percent">佣金：{{items.show_commission_rate/100}}%</span>
            </div>
          </div>
          <div class="foots">
            <span class="explain">商品{{i+1}}</span>
            <a-button @click="deleteItem(i)" type="primary" size="small" class="delete">删除</a-button>
          </div>
        </div>
      </div>
      <a-form-item class="form_btn">
        <a-button type="primary" html-type="submit">确定</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import {
  checkSpecial,
  addSpecial,
  editSpecial,
  specialDetail,
  checkSpecialBrand
} from "@/service/operate";
import { getBrands } from "@/service/goods";
import { formatCustomMethods,formatCustomStrMethods, formatKuranMethods,formatKuranStrMethods } from "./menuFormat";

export default {
  props: ["props_operateType", "props_record"],
  data() {
    return {
      form: this.$form.createForm(this),
      isBrandExit: '',  //  品牌是否存在
      formData: {
        title: "",
        brandList: [],
        goods_channel: "",
        goods_ids: [],
        custom_cat: "",  // 专场类目
        kuran_level_require: "",  // 最低等级
        weight: "",   // 排序权重
        tag1: "",
        tag2: "",
        instruction: "", //  说明
        addShopId: "",
        isAllowClick: true,
        isAllowAdd: true
      },
      isAdd: true,
      custom_catFormat: '',
      kuranFormat: '',
      shopList: [], //  添加商品下的信息
      timer: null, //  品牌搜索防抖定时器
      shopInfo: {}
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    formatCustomMethods,
    formatKuranMethods,

    // 品牌专场增加品牌唯一校验
    async checkBrand(value){
      if(!value){
        return
      }
      const { err, res } = await checkSpecialBrand({
        brand_id: this.formData.title
      });
      if (!err) {
        if (res.success) {
          this.isBrandExit = ''
        }else {
          this.isBrandExit = res.message
        }
      }
    },

    //  获取默认数据
    async getData() {
      if (this.props_operateType == "edit") {
        const { err, res } = await specialDetail(this.props_record.id);
        if (!err) {
          if (res.success) {
            this.shopInfo = res.data;
            let tempShopList = res.data.goods_infos.map(item => {
              item.channel_img =
                item.goods_channel == 2
                  ? "https://oss.zheyi360.com/app-image/weapp/images/detail/new_goods_type_douyin%402x.png"
                  : "https://oss.zheyi360.com/app-image/weapp/images/detail/new_goods_type_taobao%402x.png";
              return item;
            });
            this.shopList.push(...tempShopList);

            this.formData = {
              title: res.data.brand_id,
              brandList: [],
              goods_channel: res.data.goods_channel,
              goods_ids: res.data.goods_ids.split(","),
              custom_cat: res.data.custom_cat,
              kuran_level_require: res.data.kuran_level_require,
              weight: res.data.weight || null,
              tag1: res.data.tag1,
              tag2: res.data.tag2,
              instruction: res.data.instruction,
              addShopId: ""
            };
            this.custom_catFormat = this.formatCustomMethods(res.data.custom_cat)
            this.kuranFormat = this.formatKuranMethods(this.formData.kuran_level_require)
          }
        }
      }
    },
    // 新增/更新商品
    async addSubmit() {
      if(this.isBrandExit){
        return
      }
      if(this.formData.weight && this.formData.weight<=0 || this.formData.weight> 9999){
        this.$message.error("排序权重只能是正整数且最大值为9999");
        return;
      }

      if (!this.shopList.length) {
        this.$message.error("请添加商品信息!");
        return;
      }

      let goods_ids = this.shopList.map(item=>{
        return item.item_id_id
      });
      let params = {
        special_type: 1,
        brand_id: this.formData.title,
        goods_ids: goods_ids.join(","),
        goods_channel: this.formData.goods_channel,
        custom_cat: this.formData.custom_cat,
        kuran_level_require: this.formData.kuran_level_require,
        weight: this.formData.weight || null,
        instruction: this.formData.instruction,
        tag1: this.formData.tag1,
        tag2: this.formData.tag2
      };

      if (this.props_operateType == "add") {
        const { err, res } = await addSpecial(params);
        if (!err) {
          if (res.success) {
            this.$message.success("新增专场成功!");
            this.$emit("closeDrawers");
            this.formData.isAllowClick = true;
          }
        }
      } else {
        const { err, res } = await editSpecial(this.props_record.id, params);
        if (!err) {
          if (res.success) {
            this.$message.success("编辑专场成功!");
            this.$emit("closeDrawers");
          }
        }
      }
    },

    //  品牌搜索, 防抖
    brandSerach(value) {
      if (!value) {
        this.formData.brandList = [];
        return;
      }
      clearTimeout(this.timer);
      this.timer = setTimeout(async () => {
        const { err, res } = await getBrands({
          name: value
        });
        if (!err) {
          if (res.success) {
            this.formData.brandList = res.data.results;
          }
        }
      }, 300);
    },
    brandHandleChange(value) {
      this.formData.title = value;
      if(!value){
        this.isBrandExit = ''
      }
      this.checkBrand(value);
    },

    //  添加商品信息
    async checkGoodsData() {
      if(this.isAdd){
        this.isAdd = false;
      }else {
        return
      }

      if (!this.formData.addShopId) {
        this.$message.error("请输入商品信息");
        this.isAdd = true;
        return;
      }

      let isExist = this.shopList.some(item => {
        return item.item_id_id == this.formData.addShopId;
      });

      if (isExist) {
        this.$message.error("商品已存在");
        this.isAdd = true;
        return;
      }

      const { err, res } = await checkSpecial({
        brand_id: this.formData.title,
        item_id: this.formData.addShopId,
        goods_channel: this.formData.goods_channel
      });
      this.isAdd = true
      if (!err) {
        if (res.success) {
          let tempObj = res.data;
          tempObj.channel_img =
            res.data.goods_channel == 2
              ? "https://oss.zheyi360.com/app-image/weapp/images/detail/new_goods_type_douyin%402x.png"
              : "https://oss.zheyi360.com/app-image/weapp/images/detail/new_goods_type_taobao%402x.png";
          this.shopList.push(tempObj);
          // this.formData.goods_ids.push(res.data.item_id_id);
        }
      }
    },
    //  删除商品
    deleteItem(index) {
      this.shopList.splice(index, 1);
      this.formData.goods_ids.splice(index, 1);
      this.isAdd = true;
    },
    //  表单验证
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          //  点击编辑刚进来还没改变的时候默认值是字符串
          if(values.goods_channel!=='淘宝联盟' && values.goods_channel!=='抖音小店'){
            this.formData.goods_channel = values.goods_channel;
          }
          this.formData.custom_cat = values.custom_cat===this.custom_catFormat?this.formData.custom_cat:values.custom_cat
          this.formData.kuran_level_require = values.kuran_level_require===this.kuranFormat?this.formData.kuran_level_require:values.kuran_level_require
          this.addSubmit();
        }
      });
    },
  }
};
</script>

<style lang="less" scoped>
.shopInfo {
  display: flex;
  align-items: center;
  &:before {
    position: absolute;
    left: 59px;
    color: #f5222d;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: "*";
  }
}
.brand-info {
  display: flex;
  align-items: center;
  width: 420px;

  // i{
  //   font-size: 18px;
  //   color: red;
  //   position: absolute;
  //   margin-left: -83px;
  //   margin-top: 2px;
  // }
  button {
    margin-left: 20px;
  }
}

.container {
  display: flex;
  flex-wrap: wrap;

  .item {
    width: 200px;
    border: 1px solid #e1e2e6;
    margin-right: 15px;
    margin-bottom: 15px;

    .pic {
      width: 100%;
      height: 180px;

      img {
        width: 100%;
        height: 100%;
      }
    }
    .con-item {
      height: 95px;
      padding: 5px 0;

      .descript {
        line-height: 20px;
        padding: 0 10px;
        font-size: 13px;
        height: 60px;
      }

      .price {
        margin-top: 5px;
        display: flex;
        justify-content: space-between;
        line-height: 30px;
        padding: 0 7px;

        .p-money,
        .p-percent {
          font-weight: 500;
          color: red;
        }
      }
    }

    .foots {
      padding: 10px 7px;
      display: flex;
      justify-content: space-between;
    }
  }
}
.form_btn {
  margin: 20px 0;
}
.omit-three {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.channelImg {
  width: 28px;
  position: relative;
  top: -1px;
}
.tipBrand{
  position: absolute;
  color: red;
  margin-top: -10px;
}
</style>

<style scoped>
>>> .ant-form-item-required:before {
  display: inline-block;
  margin-right: 4px;
  color: #f5222d;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}
</style>
