<template>
  <div id="specialManage">
    <a-form layout="inline">
      <a-form-item>
        <a-select v-model="searchParams.select_type" style="width: 120px">
          <a-select-option
            v-for="item in optionarr"
            :value="item.value"
            :key="item.value"
          >{{ item.label }}</a-select-option
          >
        </a-select>
      </a-form-item>
      <a-form-item>
        <a-input v-model.trim="searchParams.search" :placeholder="`请输入${currentFieldLabel}`" allowClear />
      </a-form-item>
      <a-form-item>
        <a-select v-model="searchParams.special_type" style="width: 120px">
          <a-select-option value="0">专场类型</a-select-option>
          <a-select-option value="1">品牌专场</a-select-option>
          <a-select-option value="2">主题专场</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item>
        <a-select v-model="searchParams.status" style="width: 120px">
          <a-select-option value="0">状态</a-select-option>
          <a-select-option value="1">启用</a-select-option>
          <a-select-option value="2">禁用</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item>
        <a-select v-model="searchParams.goods_channel" style="width: 120px">
          <a-select-option value="">商品渠道</a-select-option>
<!--          <a-select-option value="1">淘宝联盟</a-select-option>-->
          <a-select-option value="2">抖音小店</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item>
        <a-select v-model="searchParams.custom_cat" style="width: 120px">
          <a-select-option value="">专场类目</a-select-option>
          <a-select-option value="8">女装</a-select-option>
          <a-select-option value="1">美妆个护</a-select-option>
          <a-select-option value="4">美食</a-select-option>
          <a-select-option value="3">居家百货</a-select-option>
          <a-select-option value="10">母婴</a-select-option>
          <a-select-option value="9">男装</a-select-option>
          <a-select-option value="5">鞋品箱包</a-select-option>
          <a-select-option value="7">珠宝配饰</a-select-option>
          <a-select-option value="12">数码家电</a-select-option>
          <a-select-option value="11">户外运动</a-select-option>
          <a-select-option value="6">文娱车品</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item>
        <a-button @click="search" type="primary" html-type="submit" :loading="loading">查询</a-button>
      </a-form-item>
      <div class="ptb-10">
        <a-button type="primary" v-auth="'operate_manage_special_add'" @click="newSpecial.isShow=true">新建专场</a-button>
      </div>
    </a-form>

    <a-modal @ok="newSpecialConfirm" v-model="newSpecial.isShow" title="新建专场" okText="下一步" width="350px">
      <a-radio-group v-model="newSpecial.value">
        <a-radio value="brand">品牌专场</a-radio>
        <a-radio value="theme">主题专场</a-radio>
      </a-radio-group>
    </a-modal>

    <a-modal @ok="startSpecialConfirm" v-model="startSpecial.isShow" :title="startSpecial.title+'专场'" width="350px">
      <span>确定要{{startSpecial.title}}该专场？</span>
    </a-modal>

    <a-table :scroll="{ x: 1200, y: tableScrollY }" v-auth="'operate_manage_special_page'" :rowKey="record => record.id" :columns="Columns" :dataSource="list" :pagination="pagination" :loading="listLoading" :locale="{ filterConfirm: '暂无数据' }" @change="handleTableChange">
      <template slot="deadlineTime" slot-scope="record">
        <span>{{record.apply_deadline_time ? record.apply_deadline_time.substr(0, 16) : ''}}</span>
      </template>
      <template slot="operation" slot-scope="record">
        <a-button class="mr-10" @click="checkClick(record)" size="small">查看</a-button>
        <a-button class="mr-10" type="primary" size="small" @click="editSpecialConfirm(record)" v-auth="'operate_manage_special_modify'">编辑</a-button>
        <a-button size="small" @click="statusClick(record)" v-auth="'operate_manage_special_modify'">{{record.status==1?'禁用':'启用'}}</a-button>
      </template>
    </a-table>
    <template>
      <a-drawer :destroyOnClose="true" :title="operateBrandDrawer.title" :width="720" :visible="operateBrandDrawer.isShow" :body-style="{ paddingBottom: '80px' }" @close="onBrandClose">
        <drawerSpecialBrand @closeDrawers="closeDrawer" :props_operateType="operateBrandDrawer.type" :props_record="operateBrandDrawer.record"></drawerSpecialBrand>
      </a-drawer>
    </template>
    <template>
      <a-drawer :destroyOnClose="true" :title="operateThemeDrawer.title" :width="720" :visible="operateThemeDrawer.isShow" :body-style="{ paddingBottom: '80px' }" @close="onThemeClose">
        <drawerSpecialTheme @closeDrawers="closeDrawer" :props_operateType="operateThemeDrawer.type" :props_record="operateThemeDrawer.record"></drawerSpecialTheme>
      </a-drawer>
    </template>

    <template>
      <a-drawer :destroyOnClose="true" title="专场详情" :width="720" :visible="specialDetaiDrawer.isShow" :body-style="{ paddingBottom: '80px' }" @close="onDetailClose">
        <drawerSpecialDetail :props_id="recordRow.id"></drawerSpecialDetail>
      </a-drawer>
    </template>
  </div>
</template>

<script>
import drawerSpecialBrand from "@/components/admin/operate/drawer-special-brand.vue";
import drawerSpecialTheme from "@/components/admin/operate/drawer-special-theme.vue";
import drawerSpecialDetail from "@/components/admin/operate/drawer-special-detail.vue";
import {
  specialList,
  addShop,
  editShop,
  specialSwitch
} from "@/service/operate";
import { checkPer, value2label } from "@/utils/index";

/**
 * @Author: 矢车
 * @Date: 2020-07-08 10:08:51
 * @Description: 表格显示字段配置
 */
const Columns = [
  {
    title: "专场ID",
    dataIndex: "id",
    fixed: 'left',
    align: "center",
    width: 130
  },
  {
    title: "专场名称",
    fixed: 'left',
    dataIndex: "title",
    align: "center",
    width: 230
  },
  {
    title: "排序权重",
    dataIndex: "weight",
    align: "center",
    width: 100
  },
  {
    title: "报名场数",
    dataIndex: "apply_num",
    align: "center",
    width: 100
  },
  {
    title: "专场类型",
    dataIndex: "special_typeFormat",
    align: "center",
    width: 120
  },
  {
    title: "状态",
    dataIndex: "statusFormat",
    align: "center",
    width: 60
  },
  {
    title: "更新人",
    dataIndex: "modified_by__username",
    align: "center",
    width: 80
  },
  {
    title: "最后编辑",
    dataIndex: "update_time",
    align: "center",
    width: 180
  },
  {
    title: "操作",
    align: "center",
    fixed: 'right',
    width: 200,
    scopedSlots: { customRender: "operation" }
  }
];

export default {
  name: "special",
  components: {
    drawerSpecialBrand, // 新建/编辑品牌专场
    drawerSpecialTheme,
    drawerSpecialDetail //  详情
  },
  data() {
    return {
      optionarr: [  { label: '专场名称', value: 'title' },
        { label: '专场ID', value: 'code' },],
      tableScrollY: 600,
      loading: false,
      newSpecial: {
        //  新建专场
        isShow: false,
        value: "brand"
      },
      startSpecial: {
        //  启用/禁用专场
        isShow: false,
        value: "open",
        title: "启用"
      },
      operateBrandDrawer: {
        //  新增修改品牌专场drawer
        isShow: false,
        title: "新建品牌专场",
        type: "", //  操作类型(新增add 或 修改 edit)
        record: "" //  点击编辑需传值的record数据
      },
      operateThemeDrawer: {
        //  新增修改主题专场drawer
        isShow: false,
        title: "新建主题专场",
        type: "", //  操作类型(新增add 或 修改 edit)
        record: "" //  点击编辑需传值的record数据
      },
      specialDetaiDrawer: {
        //  详情modal
        isShow: false
      },
      searchParams: {
        select_type: "title",
        search: "",
        special_type: "0",
        status: "0",
        goods_channel: "",
        custom_cat: ""
      }, //  搜索字段
      visible: true,
      modalTitle: "新建店铺", //  新增修改弹层
      presentStatus: "add", // 当前状态是新增还是修改
      form: this.$form.createForm(this, { name: "coordinated" }),
      isOperateShop: false,
      shopName: "",
      shopCode: "",
      recordRow: "", //  点击某一行的数据
      Columns,
      listLoading: true,
      list: [],
      pagination: {
        current: 1,
        pageSize: 10,
        pageSizeOptions: ["10", "20", "30", "40", "50"],
        showTotal: total =>
          `共 ${total} 条记录 第 ${this.pagination.current}/${Math.ceil(
            total / this.pagination.pageSize
          )} 页`,
        showQuickJumper: true,
        showSizeChanger: true,
        onShowSizeChange: (current, pageSize) =>
          (this.pagination.pageSize = pageSize)
      }
    };
  },
  computed: {
    // input的placeholder
    currentFieldLabel() {
      return value2label(this.searchParams.select_type, this.optionarr);
    },
  },
  created() {
    var windowHeight = document.documentElement.clientHeight || 1000;
    this.tableScrollY = windowHeight - 335;
  },
  mounted() {
    this.getTopicList();
  },
  methods: {
    /**
     * @Author: 矢车
     * @Date: 2020-07-08 11:01:19
     * @Description: 新建/编辑 品牌/主题 专场
     */
    // 新建下一步
    newSpecialConfirm() {
      if (this.newSpecial.value === "brand") {
        this.operateBrandDrawer.type = "add";
        this.operateBrandDrawer.isShow = true;
      } else {
        this.operateThemeDrawer.type = "add";
        this.operateThemeDrawer.isShow = true;
      }
      //  关闭本身modal
      this.newSpecial.isShow = false;
    },
    // 编辑下一步
    editSpecialConfirm(record) {
      if (record.special_type == 1) {
        this.operateBrandDrawer.type = "edit";
        this.operateBrandDrawer.record = record;
        this.operateBrandDrawer.isShow = true;
        this.operateBrandDrawer.title = "编辑品牌专场";
      } else {
        this.operateThemeDrawer.type = "edit";
        this.operateThemeDrawer.record = record;
        this.operateThemeDrawer.isShow = true;
        this.operateThemeDrawer.title = "编辑主题专场";
      }
    },

    onThemeClose() {
      this.operateThemeDrawer.isShow = false;
    },

    onBrandClose() {
      this.operateBrandDrawer.isShow = false;
      this.operateThemeDrawer.isShow = false;
    },

    /**
     * @Author: 矢车
     * @Date: 2020-07-08 11:47:17
     * @Description: 启用/禁用专场modal方法
     */
    // 点击启用/禁用
    statusClick(record) {
      this.recordRow = record;
      this.startSpecial.title = record.status == 1 ? "禁用" : "启用";
      this.startSpecial.isShow = true;
    },
    // 点击确定
    async startSpecialConfirm() {
      const { err, res } = await specialSwitch(this.recordRow.id, {
        status: this.startSpecial.title == "启用" ? 1 : 2
      });
      if (!err) {
        if (res.success) {
          this.$message.success(this.startSpecial.title + "专场成功");
          this.startSpecial.isShow = false;
          this.getTopicList(this.searchParams.search, this.searchParams.special_type == "0"? "": this.searchParams.special_type,this.searchParams.status == "0" ? "" : this.searchParams.status,this.searchParams.goods_channel,this.searchParams.custom_cat);
        }
      }
    },

    /**
     * @Author: 矢车
     * @Date: 2020-07-08 16:07:37
     * @Description: 详情drawer方法
     */
    checkClick(record) {
      this.recordRow = record;
      this.specialDetaiDrawer.isShow = true;
    },
    onDetailClose() {
      this.specialDetaiDrawer.isShow = false;
    },

    //  关闭所有drawer
    closeDrawer() {
      this.operateThemeDrawer.isShow = false;
      this.operateBrandDrawer.isShow = false;

      //  刷新列表数据
      this.getTopicList();
    },

    //  清空表单数据
    clearData() {
      this.shopName = "";
      this.shopCode = "";
    },
    // 操作店铺
    async operateShopRequest(e) {
      e.preventDefault();
      this.form.validateFields(async (err, values) => {
        if (!err) {
          console.log(values);
          //  当前是新增还是修改
          let status;
          if (this.presentStatus === "add") {
            status = await addShop({
              shop_title: this.shopName,
              code: this.shopCode
            });
          } else {
            status = await editShop(this.recordRow.id, {
              shop_title: this.shopName,
              code: this.shopCode
            });
          }
          const { err, res } = status;
          if (!err) {
            if (res.success) {
              this.$message.success(
                this.presentStatus === "add"
                  ? "新增专场成功！"
                  : "修改专场成功！"
              );
              this.clearData();
              this.isOperateShop = false;
              this.getTopicList();
            }
          }
        }
      });
    },
    //  搜索
    search() {
      this.pagination.current = 1
      //  为 0 不传当前字段
      this.getTopicList(
        this.searchParams.search,
        this.searchParams.special_type == "0"
          ? ""
          : this.searchParams.special_type,
        this.searchParams.status == "0" ? "" : this.searchParams.status,
        this.searchParams.goods_channel,
        this.searchParams.custom_cat
      );
    },
    operateShop(type, record) {
      this.presentStatus = type;
      this.isOperateShop = true;
      this.clearData();

      if (type === "add") {
        this.modalTitle = "新建店铺";
      } else {
        this.modalTitle = "编辑店铺";
        this.form.setFieldsValue("note", this.shopName); //  设置值
        this.shopName = record.shop_title;
        this.shopCode = record.code;
        this.recordRow = record; //  当前点击某一行的数据
      }
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log("Received values of form: ", values);
        }
      });
    },
    addConfirm() {
      //  添加店铺
      this.isAddShop = true;
    },
    addConfirmLoad() {
      this.handleSubmit();
    },
    addCancel() {},
    // 搜索回调
    handleSearchCallback(searchParams) {
      this.searchParams = searchParams;
      this.getTopicList();
    },
    // 获取专题列表
    async getTopicList(search, special_type, status, goods_channel, custom_cat) {
      if (!checkPer("operate_manage_special_list")) {
        this.$message.error("无权限获取列表");
        return false;
      }
      this.listLoading = true;
      const { err, res } = await specialList({
        search,
        special_type,
        status,
        goods_channel,
        custom_cat,
        page: this.pagination.current,
        page_size: this.pagination.pageSize
      });
      this.listLoading = false;
      if (!err) {
        if (res.success) {
          this.list = res.data.results.map(item => {
            item.statusFormat = item.status == "1" ? "启用" : "禁用";
            item.special_typeFormat =
              item.special_type == "1" ? "品牌专场" : "主题专场";
            return item;
          });
          const pagination = { ...this.pagination };
          pagination.total = res.data.count;
          this.pagination = pagination;
        } else {
          this.$message.warning(res.message);
        }
      }
    },
    // 点击查看报名详情
    handleShowTopicPreview(id) {
      this.$router.push({
        path: "/admin/operate/topicRegDetail",
        query: { id }
      });
    },
    // 分类
    handleTableChange(pagination) {
      if (pagination) this.pagination.current = pagination.current;
      this.getTopicList(
        this.searchParams.search,
        this.searchParams.special_type == "0"
          ? ""
          : this.searchParams.special_type,
        this.searchParams.status == "0" ? "" : this.searchParams.status,
        this.searchParams.goods_channel,
        this.searchParams.custom_cat
      );
    }
  }
};
</script>

<style lang="less" scoped>
#specialManage {
  .ptb-10 {
    padding: 10px 0;
  }
  /deep/ .ant-table-body {
    //样式穿透
    overflow-x: auto !important;
  }
}

.form_btn {
  display: flex;
  justify-content: center;
}

.mr-30 {
  margin-right: 30px;
}
.mr-10 {
  margin-right: 10px;
}
</style>


