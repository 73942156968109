/*
 * @Author: 矢车
 * @Date: 2020-09-14 18:29:29
 * @LastEditors: 矢车
 * @LastEditTime: 2020-09-18 16:45:11
 * @Description: 数据格式化
 */

/**
 * @Author: 矢车
 * @Date: 2020-09-14 18:34:09
 * @Description: 专场类目
 */
export const formatCustomMethods = (val) => {
  let valFormat
  switch (val) {
    case 8:
      valFormat = '女装'
      break
    case 1:
      valFormat = '美妆个护'
      break
    case 4:
      valFormat = '美食'
      break
    case 3:
      valFormat = '居家百货'
      break
    case 10:
      valFormat = '母婴'
      break
    case 9:
      valFormat = '男装'
      break
    case 5:
      valFormat = '鞋品箱包'
      break
    case 7:
      valFormat = '珠宝配饰'
      break
    case 12:
      valFormat = '数码家电'
      break
    case 11:
      valFormat = '户外运动'
      break
    case 6:
      valFormat = '文娱车品'
      break
  }
  return valFormat
}

/**
 * @Author: 矢车
 * @Date: 2020-09-14 18:39:17
 * @Description: 最低等级
 */
export const formatKuranMethods = (val) => {
  let valFormat
  switch (val) {
    case 1:
      valFormat = 'LV1'
      break
    case 2:
      valFormat = 'LV2'
      break
    case 3:
      valFormat = 'LV3'
      break
    case 4:
      valFormat = 'LV4'
      break
    case 5:
      valFormat = 'LV5'
      break;
  }
  return valFormat
}


/**
 * @Author: 矢车
 * @Date: 2020-09-14 18:58:10
 * @Description: 审核状态
 */
export const auditStatus = (val) => {
  let valFormat
  switch (val) {
    case 1:
      valFormat = '待审核'
      break;
    case 2:
      valFormat = '已通过'
      break;
    case 3:
      valFormat = '未通过'
      break;
    default:
      valFormat = '已取消'
  }
  return valFormat
}
