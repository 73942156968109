<!--
 * @Author: 矢车
 * @Date: 2020-07-08 15:05:17
 * @LastEditors: 矢车
 * @LastEditTime: 2020-09-16 12:38:29
 * @Description:
-->
<template>
  <div>
    <a-tabs default-active-key="1">
      <a-tab-pane key="1" tab="专场详情">
        <div class="content">
          <p class="item">
            <span class="title" v-text="special_type==1?'品牌：':'专场名称：'"></span>
            <span class="con">{{specialInfo.title}}</span>
          </p>
          <!--<p class="item">
            <span class="title">商品渠道：</span>
            <span class="con">{{specialInfo.goods_channel==1?'淘宝联盟':'抖音小店'}}</span>
          </p>-->
          <p class="item">
            <span class="title">专场类目：</span>
            <span class="con">{{custom_catFormat}}</span>
          </p>
          <p class="item">
            <span class="title">最低等级：</span>
            <span class="con">{{kuranFormat}}</span>
          </p>
          <p class="item">
            <span class="title">排序权重：</span>
            <span class="con">{{specialInfo.weight}}</span>
          </p>
          <p class="item" v-if="specialInfo.tag1">
            <span class="title">标签1：</span>
            <span class="con">{{specialInfo.tag1}}</span>
          </p>
          <p class="item" v-if="specialInfo.tag2">
            <span class="title">标签2：</span>
            <span class="con">{{specialInfo.tag2}}</span>
          </p>
          <p class="item itemFlex" v-if="specialInfo.introduce">
            <span class="title">专场简介：</span>
            <span class="con">{{specialInfo.introduce}}</span>
          </p>
          <p class="item itemFlex" v-if="specialInfo.instruction">
            <span class="title">专场说明：</span>
            <span class="con">{{specialInfo.instruction}}</span>
          </p>
          <p class="item itemFlex" v-if="specialInfo.special_pic">
            <span class="title">专场图片：</span>
            <img :src="specialInfo.special_pic" alt="">
          </p>
          <p class="item">
            <span class="title">商品信息：</span>
          </p>
        </div>

        <div class="container">
          <div class="item" v-for="(itemChild,i) in specialInfo.goods_infos" :key="i">
            <div class="pic"><img :src="itemChild.item_id__pict_url" alt=""></div>
            <div class="con-item">
              <div class="descript omit-three">
                <img class="channelImg" :src="itemChild.goods_channel==2?'https://oss.zheyi360.com/app-image/weapp/images/detail/new_goods_type_douyin%402x.png':'https://oss.zheyi360.com/app-image/weapp/images/detail/new_goods_type_taobao%402x.png'" alt="">
                {{itemChild.item_id__title}}</div>
              <div class="price">
                <span class="p-money">￥{{itemChild.show_price}}</span>
                <span class="p-percent">{{itemChild.show_commission_rate/100}}%</span>
              </div>
            </div>
          </div>
        </div>
      </a-tab-pane>

      <a-tab-pane key="2" tab="报名详情" style="color:rgba(0, 0, 0, 0.85)">
        <a-table v-auth="'account_manage_list'" :rowKey="record => record.id" :columns="Columns" :dataSource="list" :pagination="pagination" :loading="listLoading" :locale="{ filterConfirm: '暂无数据' }" @change="handleTableChange">
          <template slot="deadlineTime" slot-scope="record">
            <span>{{record.apply_deadline_time ? record.apply_deadline_time.substr(0, 16) : ''}}</span>
          </template>
          <template slot="operation" slot-scope="record">
            <a-button type="primary" size="small" @click="operateShop('edit',record)" v-auth="'goods_manage_modify_goods'"> <a :href="'/admin/user/detail/'+record.user_id" target="_blank" v-auth="'user_manage_user_detail_page'">查看</a>
            </a-button>
          </template>
        </a-table>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import { specialApply, specialDetail } from "@/service/operate";
import { formatCustomMethods, formatKuranMethods } from "@/components/admin/operate/menuFormat";

const Columns = [
  {
    title: "用户昵称",
    dataIndex: "nick",
    align: "center",
    width: 130
  },
/*  {
    title: "渠道",
    dataIndex: "channelFormat",
    align: "center",
    width: 130
  },*/
  {
    title: "注册手机",
    dataIndex: "mobile",
    align: "center",
    width: 130
  },
  {
    title: "直播排期",
    dataIndex: "start_time",
    align: "center",
    width: 160
  },
  {
    title: "报名时间",
    dataIndex: "create_time",
    align: "center",
    width: 160
  },
  {
    title: "操作",
    align: "center",
    width: 100,
    scopedSlots: { customRender: "operation" }
  }
];

export default {
  props: ["props_id"],
  data() {
    return {
      /* 专场详情 */
      specialInfo: {},
      custom_catFormat: '',
      kuranFormat: '',
      special_type: '',
      Columns,
      listLoading: true,
      list: [],
      searchParams: {
        selectType: "title",
        name: ""
      }, //  搜索字段
      pagination: {
        current: 1,
        pageSize: 10,
        pageSizeOptions: ["10", "20", "30", "40", "50"],
        showTotal: total =>
          `共 ${total} 条记录 第 ${this.pagination.current}/${Math.ceil(
            total / this.pagination.pageSize
          )} 页`,
        showQuickJumper: true,
        showSizeChanger: true,
        onShowSizeChange: (current, pageSize) =>
          (this.pagination.pageSize = pageSize)
      }
    };
  },
  mounted() {
    this.getSpecailData();
    this.getTopicList();
  },
  methods: {
    formatCustomMethods,
    formatKuranMethods,
    // 获取专场详情数据
    async getSpecailData() {
      const { err, res } = await specialDetail(this.props_id);
      if (!err) {
        if (res.success) {
          this.specialInfo = res.data;
          this.custom_catFormat = this.formatCustomMethods(this.specialInfo.custom_cat)
          this.kuranFormat = this.formatKuranMethods(this.specialInfo.kuran_level_require)
        }
      }
    },
    async getTopicList() {
      this.listLoading = true;
      const { err, res } = await specialApply({
        special_id: this.props_id
      });
      this.listLoading = false;
      if (!err) {
        if (res.success) {
          this.list = res.data.results.map(item => {
            item.channelFormat = item.channel == 2 ? "淘宝" : "抖音";
            return item;
          });
        } else {
          this.$message.warning(res.message);
        }
      }
    },

    // 跳页
    handleTableChange(pagination) {
      if (pagination) this.pagination.current = pagination.current;
      this.getTopicList();
    }
  }
};
</script>

<style lang="less" scoped>
.content {
  display: grid;
  grid-template-columns: 1fr;
  color: rgba(0, 0, 0, 0.85);

  .item {
    line-height: 30px;
    font-size: 15px;
    margin-bottom: 15px;
    display: flex;

    .title {
      display: inline-block;
      padding-left: 15px;
      width: 100px;
    }

    .con {
      display: inline-block;
      max-width: 400px;
    }

    img {
      max-width: 150px;
      max-height: 150px;
      margin-top: 10px;
      margin-left: 10px;
    }
  }
  .itemFlex {
    display: flex;
  }
}

.specialInfo {
  margin: 10px;
}

.container {
  display: flex;
  flex-wrap: wrap;
  margin-left: 15px;
  color: rgba(0, 0, 0, 0.85);

  .item {
    width: 200px;
    border: 1px solid #e1e2e6;
    border-radius: 5px;
    margin-right: 15px;
    margin-bottom: 10px;

    .pic {
      width: 100%;
      height: 200px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .con-item {
      height: 100px;
      padding: 5px 0;

      .descript {
        line-height: 20px;
        padding: 0 10px;
        font-size: 13px;
        height: 60px;
      }

      .price {
        margin-top: 5px;
        display: flex;
        justify-content: space-between;
        line-height: 30px;
        padding: 0 7px;

        .p-money,
        .p-percent {
          font-weight: 500;
          color: red;
        }
      }
    }
  }
  .channelImg {
    width: 28px;
    position: relative;
    top: -1px;
  }
}
.omit-three {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  font-weight: 600;
}
</style>
